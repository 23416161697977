"use client"

import { createContext } from "react"

export interface TypesenseProviderProps {
  typesenseHost: string
  children: React.ReactNode
}

export interface TypesenseContextProps {
  typesenseHost: string
}

export const TypesenseContext = createContext<TypesenseContextProps>({
  typesenseHost: "",
})

export const TypesenseProvider = (props: TypesenseProviderProps) => {
  return (
    <TypesenseContext.Provider
      value={{
        typesenseHost: props.typesenseHost,
      }}
    >
      {props.children}
    </TypesenseContext.Provider>
  )
}
