"use client"
import {
  Autocomplete,
  Link,
  PaperProps,
  PopperProps,
  Stack,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  autocompleteClasses,
} from "@mui/material"
import { SearchIcon, SmallErrorIcon } from "@tc/ui-dfe/icons"
import { TypesenseContext, useTypesenseClient } from "@tc/ui-typesense-client"
import { get } from "lodash"
import { ErrorInfo, useContext, useEffect, useMemo, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { FieldValues, UseControllerProps, useController } from "react-hook-form"
import {
  InstantSearch,
  useInfiniteHits,
  useSearchBox,
} from "react-instantsearch"
import { getCollectionDefaultSettings } from "./CollectionConfig"

interface FormTypesenseAutocompletesProps {
  paper?: PaperProps
  popper?: Partial<PopperProps>
}

export interface condition {
  attribute: string
  value: string
}

export type FormTypesenseAutocompleteProps<T extends FieldValues> =
  TextFieldProps &
    UseControllerProps<T> & {
      multiple?: boolean
      popupIcon?: React.ReactNode
      clearIcon?: React.ReactNode
      typesenseApiKeyMutation: string
      disableClearable?: boolean
      componentsProps?: FormTypesenseAutocompletesProps
      hideHelperTextSpace?: boolean
      collectionName: string
      valueAttribute?: string
      queryAttributes?: string
      displayAttr?: string
      customOption?: (item: object) => string
      placeholder?: string
      onHitChange?: (hit: object | undefined) => void
      useDefaultCollectionAttributes?: boolean
      precondition?: condition
      isReadOnly?: boolean
    }

export interface Option {
  label: string
  value?: string
  count?: number
}

export const FormTypesenseAutocomplete = <T extends FieldValues>(
  props: FormTypesenseAutocompleteProps<T>,
) => {
  const context = useContext(TypesenseContext)

  let controlProps: FormTypesenseAutocompleteProps<T> = { ...props }
  if (props.useDefaultCollectionAttributes) {
    controlProps = {
      ...props,
      ...(getCollectionDefaultSettings(props.collectionName) || {}),
    }
  }

  const weights = (controlProps.queryAttributes || "")
    .split(",")
    .map((_) => "1")
    .join(",")

  let searchParams: object = {
    query_by: controlProps.queryAttributes,
    query_by_weights: weights,
    typoTokensThreshold: 0,
    page: 50,
  }

  if (props.precondition) {
    searchParams = {
      ...searchParams,
      filter_by: `${props.precondition.attribute}:=${props.precondition.value}`,
    }
  }

  const { adapter } = useTypesenseClient({
    apiKeyMutation: props.typesenseApiKeyMutation,
    typesenseUrl: `${context.typesenseHost}/search`,
    searchParams: searchParams,
  })

  const control = useMemo(
    () => <SearchAutocompleteControl {...controlProps} />,
    [controlProps],
  )

  if (!adapter || !adapter?.searchClient) {
    return <TextField InputProps={{ readOnly: true }} />
  }

  const logError = (error: Error, info: ErrorInfo) => {
    console.error(error)
    console.log(info)
  }

  return (
    <ErrorBoundary
      fallback={<div>search not available</div>}
      onError={logError}
    >
      <InstantSearch
        searchClient={adapter?.searchClient}
        indexName={props.collectionName}
      >
        {control}
      </InstantSearch>
    </ErrorBoundary>
  )
}

interface SelectType {
  value: string
  label: string
  originHit?: object
}

const SearchAutocompleteControl = <T extends FieldValues>({
  valueAttribute,
  customOption,
  name,
  control,
  rules,
  displayAttr,
  disabled,
  popupIcon,
  placeholder,
  clearIcon,
  onHitChange,
  label,
}: FormTypesenseAutocompleteProps<T>) => {
  const [allHits, setAllHits] = useState<object[]>([])

  const key = valueAttribute as keyof object

  const { hits, showMore, isLastPage } = useInfiniteHits({})
  const {
    field,
    formState: { errors },
  } = useController({ name, control, rules })
  const error = get(errors, name)
  const { refine } = useSearchBox({})
  const defaultValue = {
    value: field.value || "",
    label: "",
  }
  const [value, setValue] = useState<SelectType>(defaultValue)

  const [init, setInit] = useState(true)

  useEffect(() => {
    if (field.value && init) {
      if (Array.isArray(field.value) && field.value.length <= 0) {
        refine("*")
        return
      }
      refine(field.value)
      refine("*")
    }
  }, [field.value, init, refine])

  const getLabel = (hit: object) =>
    customOption ? customOption(hit) : hit[displayAttr as keyof object]

  useEffect(() => {
    setAllHits((current) => {
      const result = [...current, ...hits]
      return [...new Map(result.map((item) => [item[key], item])).values()]
    })
    if (init) {
      if (field.value && hits.length > 0) {
        const hit = hits.find((hit) => hit[key] === field.value)
        if (hit) {
          setValue({
            value: hit[key] as string,
            label: getLabel(hit),
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hits])

  const onChange = (data: SelectType | null) => {
    setInit(false)
    if (!data) {
      setValue(defaultValue)
      onHitChange && onHitChange(undefined)
      return field.onChange("")
    }

    setValue(data)
    field.onChange(data.value)
    onHitChange && onHitChange(data.originHit)
  }

  let options: SelectType[] = [...allHits].map((hit) => ({
    value: hit[key] as string,
    label: getLabel(hit),
    originHit: hit,
  }))

  options = [{ label: "-", value: "" }, ...options]

  if (!isLastPage) {
    options = [...options, { label: "show more", value: "_show_more" }]
  }

  const showMoreClick = (event: { preventDefault: () => void }) => {
    event && event.preventDefault()
    showMore()
  }

  return (
    <Autocomplete
      options={options}
      disabled={disabled}
      value={value}
      clearIcon={clearIcon}
      data-testid="test-typesense-autocomplete"
      sx={{
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: "none",
        },
      }}
      popupIcon={popupIcon || <SearchIcon />}
      autoHighlight
      isOptionEqualToValue={(optionItem: SelectType, valueItem: SelectType) =>
        optionItem.value === valueItem.value
      }
      onInputChange={(_event, inputValue, reason) => {
        reason !== "reset" && refine(inputValue || "*")
      }}
      onChange={(_event, item) => {
        onChange(item)
      }}
      getOptionLabel={(optionItem: SelectType) => optionItem?.label}
      renderOption={(attrs, hit) => {
        if (hit.value === "_show_more") {
          return (
            <Typography
              {...attrs}
              key={hit.value}
              sx={{
                whiteSpace: "pre-wrap",
              }}
              onClick={(event) => showMoreClick(event)}
            >
              <Link underline="hover">{hit.label}</Link>
            </Typography>
          )
        }
        return (
          <Typography
            {...attrs}
            key={hit.value}
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {hit.label}
          </Typography>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error?.message}
          placeholder={placeholder}
          label={label}
          inputProps={{
            ...params.inputProps,
          }}
          helperText={
            (error?.message as string) && (
              <Stack direction="row" alignItems="center" gap={0.5}>
                <SmallErrorIcon
                  sx={{
                    width: 18,
                    fill: (theme: Theme) => theme.palette.error.main,
                  }}
                />
                <Typography variant="body2" color="error">
                  {(error?.message as string) || " "}
                </Typography>
              </Stack>
            )
          }
        />
      )}
    />
  )
}
