import { useAlert } from "@tc/ui-shared/hooks"
import {
  Document,
  MenuItemKeyValuePair,
  SearchParameters,
} from "@tc/ui-shared/utils"
import { TypesenseContext, useTypesenseClient } from "@tc/ui-typesense-client"
import { useCallback, useContext } from "react"

export const useSearch = (apiKeyMutation: string) => {
  const alert = useAlert()
  const context = useContext(TypesenseContext)
  const { fetching: searchFetching, adapter } = useTypesenseClient({
    apiKeyMutation: apiKeyMutation,
    typesenseUrl: `${context.typesenseHost}/search`,
    searchParams: {
      query_by: "label",
    },
  })
  const searchData = useCallback(
    async (request: {
      collectionName: string
      searchParameters: SearchParameters
    }) => {
      try {
        const data = await adapter?.typesenseClient
          .collections(request.collectionName)
          .documents()
          .search(request.searchParameters, {})
        if (!data?.hits || data.hits.length === 0) {
          return []
        } else {
          return data.hits.map((hit) => {
            const document: Document = hit.document as Document
            return { value: document.id, label: document.label }
          })
        }
      } catch (error) {
        if (error instanceof Error) {
          alert.error(error.message)
        }

        alert.error("There is an error when retrieving data")
        return []
      }
    },
    [adapter, searchFetching],
  )

  const getSearchCollectionData = useCallback(
    async (collectionNames: string[]) => {
      if (!adapter) return []

      // region - This code is needed otherwise it will throw error when running teo e2e in tst1 pipeline because front end in Playwright is so fast that might send request to Typesense before getting Typesense api key from GraphQL API
      const apiKeyItem = localStorage.getItem("type-sense-apikey")
      if (!apiKeyItem) return []
      // endregion

      const collectionData: { key: string; value: MenuItemKeyValuePair[] }[] =
        []

      await Promise.all(
        collectionNames.map(async (collectionName: string) => {
          const searchParameters: SearchParameters = {
            q: "*",
            query_by: "label",
            per_page: 250,
          }
          const searchResult = await searchData({
            collectionName: collectionName,
            searchParameters: searchParameters,
          })

          collectionData.push({
            key: collectionName,
            value: searchResult ?? [],
          })
        }),
      )

      return collectionData
    },
    [adapter],
  )

  return {
    adapter,
    getSearchCollectionData,
  }
}
