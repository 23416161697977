"use client"
import {
  Autocomplete,
  Chip,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material"
import Stack from "@mui/material/Stack"
import { MenuItemKeyValuePair, SearchParameters } from "@tc/ui-shared/utils"
import { debounce } from "lodash"
import { useCallback, useEffect, useId, useState } from "react"
import {
  Controller,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form"

export type FormSearchProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    collection: string
    multiple: boolean
    searchAsYouType: boolean
    searchFetching: boolean
    searchData: (request: {
      collectionName: string
      searchParameters: SearchParameters
    }) => Promise<MenuItemKeyValuePair[]>
  }

// TODO change all single and multiple values to use array
export const FormSearch = <T extends FieldValues>(
  props: FormSearchProps<T>,
) => {
  const {
    id,
    name,
    control,
    collection,
    multiple,
    searchAsYouType,
    searchFetching,
    searchData,
    rules,
    ...textFieldProps
  } = props
  const autoId = useId()
  const _id = id || autoId
  const fieldState = control?.getFieldState(name)
  const [value, setValue] = useState<MenuItemKeyValuePair[] | null>(null)
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState<MenuItemKeyValuePair[]>([])
  const [open, setOpen] = useState(false)
  const loading = open && options.length === 0 && !searchAsYouType
  const { field } = useController({
    name,
    control,
  })

  const search = useCallback(
    debounce(
      async (request: {
        collectionName: string
        searchParameters: SearchParameters
      }) => {
        const searchResult = await searchData(request)
        if (!searchResult) return
        setOptions([...searchResult])
      },
      50,
    ),
    [searchData],
  )

  //populate the value when viewing the page
  useEffect(() => {
    if (field.value) {
      setValue(field.value)
    } else {
      setValue(null)
    }

    // TODO once server side caching is done, can just pass the options as parameter to improve performance
    if (!searchAsYouType && options?.length === 0) {
      const searchParameters: SearchParameters = {
        q: "*",
        query_by: "label",
      }
      searchParameters.q = "*"
      //Only up to 250 hit can be fetched per page. Useful for small collection data
      searchParameters.per_page = 250

      search({
        collectionName: collection,
        searchParameters: searchParameters,
      })

      return
    }
  }, [])

  //Search when user type depending on the control parameters
  useEffect(() => {
    if (searchAsYouType && inputValue) {
      const searchParameters: SearchParameters = {
        q: "*",
        query_by: "label",
      }

      searchParameters.q = inputValue
      searchParameters.per_page = 10
      searchParameters.infix = "always"

      search({
        collectionName: collection,
        searchParameters: searchParameters,
      })
    }
  }, [inputValue, value, search])

  return (
    <Stack>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <Autocomplete
              id={_id}
              open={open}
              onOpen={() => {
                setOpen(true)
              }}
              onClose={() => {
                setOpen(false)
              }}
              data-testid="test-formTypesense"
              getOptionLabel={(
                option: MenuItemKeyValuePair | MenuItemKeyValuePair[],
              ) => {
                if (!option || (Array.isArray(option) && option.length === 0))
                  return ""
                return Array.isArray(option) ? option[0]?.label : option?.label
              }}
              isOptionEqualToValue={(
                option: MenuItemKeyValuePair | MenuItemKeyValuePair[],
                value: MenuItemKeyValuePair | MenuItemKeyValuePair[],
              ) => {
                if (
                  !value ||
                  !option ||
                  (Array.isArray(value) && value.length === 0) ||
                  (Array.isArray(option) && option.length === 0)
                )
                  return false

                const v = Array.isArray(value) ? value[0] : value

                return Array.isArray(option)
                  ? option[0].label === v.label
                  : option.label === v.label
              }}
              options={options}
              value={value}
              multiple={multiple}
              limitTags={2}
              loading={loading}
              noOptionsText="No data"
              onChange={(_, newValue) => {
                if (!newValue) {
                  setValue(null)
                  field.onChange(newValue)
                  return
                }

                const newValues = newValue as MenuItemKeyValuePair[]

                //TODO sort correctly
                if (Array.isArray(newValue)) {
                  setOptions(
                    newValues
                      ? [...new Set(newValues.concat(options))]
                      : options,
                  )
                  setValue(newValues)
                } else {
                  setOptions(
                    newValue
                      ? [...new Set([newValue].concat(options))]
                      : options,
                  )
                  setValue([newValue])
                }

                field.onChange([newValue])
              }}
              onInputChange={(_, newInputValue) => {
                if (newInputValue == "") {
                  return
                }
                setInputValue(newInputValue)
              }}
              renderOption={(props, option, { selected }) => {
                const item: MenuItemKeyValuePair = Array.isArray(option)
                  ? option[0]
                  : option
                return (
                  <MenuItem {...props} key={item.value} selected={selected}>
                    {item.label}
                  </MenuItem>
                )
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={Array.isArray(option) ? option[0].value : option.value}
                    label={
                      Array.isArray(option) ? option[0].label : option.label
                    }
                  />
                ))
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    {...field}
                    error={fieldState?.invalid}
                    helperText={
                      fieldState?.error?.message as string | undefined
                    }
                    {...textFieldProps}
                    inputRef={field.ref}
                  />
                )
              }}
            />
          )
        }}
      ></Controller>
    </Stack>
  )
}
