"use client"

import { useEffect, useState } from "react"
import TypesenseInstantSearchAdapter, {
  TypesenseInstantsearchAdapterOptions,
} from "typesense-instantsearch-adapter"
import { useMutation } from "urql"

export interface useUiTypesenseClientProps {
  apiKeyMutation: string
  typesenseUrl: string
  searchParams?: object
}

const TYPESENSE_APIKEY_IN_COOKIE = "type-sense-apikey"

export function useTypesenseClient(props: useUiTypesenseClientProps): {
  fetching: boolean
  adapter?: TypesenseInstantSearchAdapter
  adapterProps?: TypesenseInstantsearchAdapterOptions
} {
  const [fetching, setFetching] = useState(true)
  const [adapter, setAdapter] = useState<TypesenseInstantSearchAdapter>()
  const [adpaterProps, setAdapterProps] =
    useState<TypesenseInstantsearchAdapterOptions>()

  const [newSearchApiKeyResult, updateNewSearchApiKeyResult] = useMutation(
    props.apiKeyMutation,
  )

  const [apiKey, setApiKey] = useState("")

  useEffect(() => {
    if (
      newSearchApiKeyResult &&
      newSearchApiKeyResult.data?.newSearchApiKey.apiKey
    ) {
      localStorage.setItem(
        TYPESENSE_APIKEY_IN_COOKIE,
        JSON.stringify(newSearchApiKeyResult.data?.newSearchApiKey),
      )

      setApiKey(newSearchApiKeyResult.data?.newSearchApiKey.apiKey)
    }
  }, [newSearchApiKeyResult.fetching])

  useEffect(() => {
    const apiKeyItem = localStorage.getItem(TYPESENSE_APIKEY_IN_COOKIE)
    if (apiKeyItem) {
      const apiKeyObject: {
        apiKey: string
        expires: string
      } = JSON.parse(apiKeyItem)

      if (new Date(apiKeyObject.expires).getTime() < new Date().getTime()) {
        localStorage.removeItem(TYPESENSE_APIKEY_IN_COOKIE)
        updateNewSearchApiKeyResult()
        return
      }

      setApiKey(apiKeyObject.apiKey)
    } else {
      updateNewSearchApiKeyResult()
    }
  }, [])

  useEffect(() => {
    if (!apiKey) {
      return
    }
    const typesenseAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: apiKey,
        sendApiKeyAsQueryParam: false,
        nodes: [
          {
            url: props.typesenseUrl,
          },
        ],
      },
      additionalSearchParameters: props.searchParams || {},
    })
    setAdapter(typesenseAdapter)
    const options: TypesenseInstantsearchAdapterOptions = {
      server: {
        apiKey: apiKey,
        sendApiKeyAsQueryParam: false,
        nodes: [
          {
            url: props.typesenseUrl,
          },
        ],
      },
      additionalSearchParameters: props.searchParams || {},
    }
    setAdapterProps(options)
    //typesenseAdapter.updateConfiguration(options)
    setFetching(newSearchApiKeyResult.fetching)
  }, [apiKey])

  return { fetching: fetching, adapter, adapterProps: adpaterProps }
}
